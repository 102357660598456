<template>
    <div class="page-content">
        <div class="container">
            <h1> {{ pageTitle }} </h1>
            <div class="section-small" v-html="this.bankData"></div>
        </div>
    </div>
</template>

<script>
import Translation from '../../../public/translation.json'

export default {
    data() {
        return {
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            bankData: []
        }
    },
    computed: {
        pageTitle() {
            return Translation.pagetitle[this.defaultLang]['bank_details']
        },
    },
    methods: {
        getData() {
            this.$http.get(`account/user/bank-details`).then(res=> {
                console.log(res)
                if(res.data.status === true) {
                    this.bankData = res.data.data.bank_details  
                }
            })
            .catch(err=> {
                console.log(err.response.statusText)
            })
        }
    },
    mounted(){
        this.getData();
    }
}
</script>